import { useContext, useEffect, useState } from 'react'
import './StationInput.css'

import { BasicsContext } from '../contexts/BasicsContext'
import { SessionsContext } from '../contexts/SessionsContext'

interface StationInputProps {
  stations: string[];
  onStationChange: (station: string) => void;
}

export default function StationInput(props: StationInputProps) {
  const { stations, onStationChange } = props

  const { sessions } = useContext(SessionsContext)
  const { basics, basicsDispatch } = useContext(BasicsContext)

  const [ inputDisabled, setInputDisabled ] = useState(false)
  const [ showInput, setShowInput ] = useState(true)
  const [ station, setStation ] = useState('')

  useEffect(() => {
    // console.log('StationInput useEffect ran?', basics.activeStation)
    setStation(basics.activeStation)

    if (basics.activeStation === '') {
      setShowInput(true)
    } else {
      setShowInput(false)
    }

  }, [ basics.activeStation ])

  useEffect(() => {
    if (sessions.current.length > 0) {
      setShowInput(false)
      setInputDisabled(true)
    } else {
      setInputDisabled(false)
    }

  }, [ sessions ])

  const stationInput = (
    <label className="StationInput-label">
      <span className="StationInput-label-text">Station: </span>
      <select
        className="StationInput-select"
        value={ station }
        disabled={ inputDisabled }
        onChange={ (ev) => {
          const newStation = ev.target.value
          setStation(newStation)

          if (newStation !== '') {
            basicsDispatch({
              activeStation: newStation,
            })

            onStationChange(newStation)
            setShowInput(false)
          }
        } }
      >
        <option key={ '' } >Select a Station</option>
        { stations.map(station => <option key={ station }>{ station }</option>) }
      </select>
    </label>
  )

  const stationStatic = (
    <span className="StationInput-label">
      <span className="StationInput-label-text">Station: </span>
      <span className="StationInput-value-text">{ station }</span>
      <button
        type="button"
        className="StationInput-btn StationInput-btn-change"
        disabled={ inputDisabled }
        onClick={ () => setShowInput(true) }
      >Change</button>
    </span>
  )

  return (
    <div className="StationInput">
      { showInput ? stationInput : stationStatic }
    </div>
  )
}
