import { useContext } from 'react'
import './StationSettingsModal.css'

import StationSettingsForm from './StationSettingsForm'
import {
  DEFAULT_INACTIVITY_TIMEOUT,
  DEFAULT_SUBMIT_TIMEOUT,
  DEFAULT_USER_TIMEOUT,
  TimeoutsContext,
} from '../contexts/TimeoutsContext'

export default function StationSettingsModal() {
  const { timeouts, timeoutsDispatch } = useContext(TimeoutsContext)

  return (
    <div className="StationSettingsModal">

      <StationSettingsForm
        label="User Timeout"
        id="SSM-user-timeout"
        value={ (timeouts.userTimeout / 60) }
        defaultValue={ DEFAULT_USER_TIMEOUT / 60 }
        maxValue={ 120 }
        note="in minutes"
        onSubmit={ (value) => {
          timeoutsDispatch({
            userTimeout: (value * 60),
          })
        } }
      />

      <StationSettingsForm
        label="Submission Timeout"
        id="SSM-submit-timeout"
        value={ timeouts.submitTimeout }
        defaultValue={ DEFAULT_SUBMIT_TIMEOUT }
        maxValue={ 120 }
        note="in seconds"
        onSubmit={ (value) => {
          timeoutsDispatch({
            submitTimeout: value,
          })
        } }
      />

      <StationSettingsForm
        label="Inactivity Timeout"
        id="SSM-inactivity-timeout"
        value={ timeouts.inactivityTimeout }
        defaultValue={ DEFAULT_INACTIVITY_TIMEOUT }
        maxValue={ 7200 }
        note="in seconds"
        onSubmit={ (value) => {
          timeoutsDispatch({
            inactivityTimeout: value,
          })
        } }
      />
    </div>
  )
}
