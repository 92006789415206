import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'

import App from './App'
import BasicsRetriever from './retrievers/BasicsRetriever'
import TimeoutsRetriever from './retrievers/TimeoutsRetriever'
import SessionsRetriever from './retrievers/SessionsRetriever'

const container = document.getElementById('root')

const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <BasicsRetriever>
      <SessionsRetriever>
        <TimeoutsRetriever>
          <App/>
        </TimeoutsRetriever>
      </SessionsRetriever>
    </BasicsRetriever>
  </React.StrictMode>
)
