import { useContext, useEffect } from 'react'
import { useRunOnInitialMount } from '../lib/hooks'
import {
  Sessions,
  SessionsContext,
  SessionsContextProvider,
} from '../contexts/SessionsContext'

import { StorageGet } from '../lib/wts/browser_storage'

interface SessionsRetrieverProps {
  children: any;
}

export function SessionsRetrieval() {
  const { sessions, sessionsDispatch } = useContext(SessionsContext)

  useRunOnInitialMount(() => {
    console.log('!! SESSION RETRIEVAL !!')
    const storedSessions = StorageGet<Sessions>('sessions', sessions)
    const activeSessionID  = StorageGet<string>('active_session', sessions.active?.session_id || '')

    sessionsDispatch({
      ...storedSessions,
      active: storedSessions.current.find(s => s.session_id === activeSessionID),
    })
  })

  return null
}

export default function SessionsRetriever(props: SessionsRetrieverProps) {
  const { children } = props

  return (
    <SessionsContextProvider>
      <SessionsRetrieval />
      { children }
    </SessionsContextProvider>
  )
}
