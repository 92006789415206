import { useContext, useEffect, useRef, useCallback, useState } from 'react'
import { useInterval } from '../lib/hooks'
import { Session } from '../lib/wts/session'
import { secondsToElapsedTime, unixTime } from '../lib/time'
import { SessionsContext } from '../contexts/SessionsContext'
import { TimeoutsContext } from '../contexts/TimeoutsContext'

interface UserTabTitleProps {
  session: Session;
  onInactive: (session_id: string) => void;
}

export default function UserTabTitle(props: UserTabTitleProps) {
  const { timeouts } = useContext(TimeoutsContext)
  const { sessions, sessionsDispatch } = useContext(SessionsContext)
  const { session, onInactive } = props

  const timeRef = useRef(unixTime())
  const [ intSession, setIntSession ] = useState(session)
  const [ title, setTitle ] = useState<string>(session.employee)

  useEffect(() => {
    const updated = sessions.current.find(s => s.session_id === session.session_id)

    if (updated) {
      setIntSession(updated)
    }

  }, [ session, sessions ])

  const onSignOutSession = useCallback(() => {
    const updated = sessions.current.filter(s => s.session_id !== session.session_id)
    const expired = [ ...sessions.expired, session ]

    sessionsDispatch({
      active: sessions.active?.session_id === session.session_id ? undefined : sessions.active,
      current: [ ...updated ],
      expired: expired,
    })
  }, [ session, sessions, sessionsDispatch ])

  useInterval(() => {
    const now = unixTime()

    if (now > timeRef.current) {
      timeRef.current = now

      setTitle(`${ intSession.employee } (${ secondsToElapsedTime(now - intSession.last_usage) })`)

      const lastUsageDelta = now - session.last_usage

      // The sign-out event handling!
      if (lastUsageDelta > timeouts.userTimeout) {
        onSignOutSession()
      } else if (lastUsageDelta > timeouts.inactivityTimeout && sessions.active?.session_id === session.session_id) {
        console.log('!! useInterval SessionsDispatch !!')

        onInactive(session.session_id)

        sessionsDispatch({
          active: undefined,
        })
      }
    }
  }, 100)

  return <>{ title }</>
}
