import { useCallback, useContext, useEffect, useState } from 'react'

import { Activity, BasicsContext, BasicsContextProvider } from '../contexts/BasicsContext'
import { GetBasicInfo } from '../lib/wts/basic'
import { NetworkState } from '../lib/network'
import { onlyUniqueElements } from '../lib/unique'
import { getErrorString } from '../lib/error'
import { StorageGet } from '../lib/wts/browser_storage'
import { useRunOnInitialMount } from '../lib/hooks'

interface BasicsRetrieverProps {
  children: any;
}

interface BasicsRetrievalProps {
  children: any;
  onNetworkChange: (state: NetworkState, addl?: string) => void;
}

export function BasicsRetrieval(props: BasicsRetrievalProps) {
  const { basics, basicsDispatch } = useContext(BasicsContext)
  const { children, onNetworkChange } = props

  const [ networkState, setIntNetworkState ] = useState<NetworkState>(NetworkState.idle)

  const setNetworkState = useCallback((state: NetworkState, addl?: string) => {
    setIntNetworkState(state)
    onNetworkChange(state, addl)
  }, [ onNetworkChange, setIntNetworkState ])

  useRunOnInitialMount(() => {
    const activity      = StorageGet<Activity>('activity', Activity.inactive)
    const activeStation = StorageGet<string>('station', '')
    const activeTab     = StorageGet<string>('active_tab', '')

    basicsDispatch({
      activity,
      activeStation,
      activeTab,
    })
  })

  useEffect(() => {
    const doNetwork = async () => {
      setNetworkState(NetworkState.pending)

      let slowTimer: number = -1

      try {
        slowTimer = window.setTimeout(() => setNetworkState(NetworkState.slow), 4_000)

        const basics = await GetBasicInfo()

        window.clearTimeout(slowTimer)

        if (basics instanceof Error) {
          setNetworkState(NetworkState.rejected, `${ basics.name } - ${ basics.message }`)
          return
        }

        basicsDispatch({
          employees: onlyUniqueElements(basics.employees),
          stations: onlyUniqueElements(basics.stations),
        })

        setNetworkState(NetworkState.resolved)
      } catch (err) {
        setNetworkState(NetworkState.rejected, getErrorString(err))

      } finally {
        window.clearTimeout(slowTimer)
      }
    }

    if (networkState === NetworkState.idle) {
      doNetwork()
        .then(() => console.log(`Completed doNetwork [networkState=${ networkState }]`))
        .catch((err) => {
          setNetworkState(NetworkState.rejected, `${ err.name } - ${ err.message }`)
        })
    }
  }, [ basics, basicsDispatch, networkState, setNetworkState ])

  let progress: JSX.Element = <></>

  switch (networkState) {
    case NetworkState.rejected:
      progress = <div className="BIR-progress">Something went wrong!</div>
      break
    case NetworkState.pending:
      progress = <div className="BIR-progress">Loading Data... Please Wait...</div>
      break
    case NetworkState.slow:
      progress = <div className="BIR-progress">Still Loading! Taking a while...</div>
      break
  }

  return networkState === NetworkState.resolved ? children : progress
}

export default function BasicsRetriever(props: BasicsRetrieverProps) {
  const { children } = props

  return (
    <BasicsContextProvider>
      <BasicsRetrieval onNetworkChange={ () => {} }>{ children }</BasicsRetrieval>
    </BasicsContextProvider>
  )
}
