import React, { Context, useMemo, useReducer } from 'react'
import { StorageSet } from '../lib/wts/browser_storage'
import { Session } from '../lib/wts/session'


export type SessionsDispatch = React.Dispatch<Partial<Sessions>>;

export interface Sessions {
  active?: Session;
  current: Session[];
  expired: Session[];
}

export interface ISessionsContext {
  sessions: Sessions;
  sessionsDispatch: SessionsDispatch;
}

export const SessionsContext: Context<ISessionsContext> = React.createContext<ISessionsContext>({
  sessions: {
    current: [],
    expired: [],
  },
  sessionsDispatch: () => {},
})

function reducer (state: Sessions, change: Partial<Sessions>): Sessions {
  const result: Sessions = {
    ...state,
    ...change,
  }

  console.log('Sessions Reducer', result)
  StorageSet('sessions', result)

  return result
}

export function SessionsContextProvider(props: { children: any }) {
  const initial: Sessions = {
    current: [],
    expired: [],
  }

  const [ sessions, sessionsDispatch ] = useReducer(reducer, initial)

  const memoizedContext = useMemo(() => {

    return { sessions, sessionsDispatch }
  }, [ sessions, sessionsDispatch ])

  return (
    <SessionsContext.Provider value={ memoizedContext }>
      { props.children }
    </SessionsContext.Provider>
  )
}
