import { useContext } from 'react'
import {
  Timeouts,
  TimeoutsContext,
  TimeoutsContextProvider,
} from '../contexts/TimeoutsContext'
import { useRunOnInitialMount } from '../lib/hooks'
import { StorageGet } from '../lib/wts/browser_storage'

interface TimeoutsRetrieverProps {
  children: any;
}

export function TimeoutsRetrieval() {
  const { timeouts, timeoutsDispatch } = useContext(TimeoutsContext)

  useRunOnInitialMount(() => {
    const storedTimeouts = StorageGet<Timeouts>('station_timeouts', timeouts)

    timeoutsDispatch(storedTimeouts)
  })

  return null
}

export default function TimeoutsRetriever(props: TimeoutsRetrieverProps) {
  const { children } = props

  return (
    <TimeoutsContextProvider>
      <TimeoutsRetrieval />
      { children }
    </TimeoutsContextProvider>
  )
}
