import { useContext, useEffect, useState } from 'react'
import './App.css'

import StationInput from './components/StationInput'
import SignInForm from './components/SignInForm'
import { Activity, BasicsContext } from './contexts/BasicsContext'
import StationSettings from './components/StationSettings'
import SessionExpirationLogger from './components/SessionExpirationLogger'
import { SessionsContext } from './contexts/SessionsContext'
import SessionTabHandler from './components/SessionTabHandler'

export default function App() {
  const { basics, basicsDispatch } = useContext(BasicsContext)
  const { sessions } = useContext(SessionsContext)

  const [ showOutput, setShowOutput ] = useState(false)

  useEffect(() => {
    if (typeof basics.activeStation === 'string' && basics.activeStation !== '') {
      setShowOutput(true)
    }
  }, [ basics.activeStation ])

  // "Activity" background color handler
  useEffect(() => {
    basicsDispatch({
      activity: sessions.current.length ? Activity.active : Activity.inactive,
    })
  }, [ sessions ])

  let output: JSX.Element = <></>

  if ( ! sessions.active && sessions.current.length === 0) {
    output = (
      <SignInForm
        onSignIn={ () => {} }
      />
    )
  } else {
    output = (
      <SessionTabHandler />
    )
  }

  return (
    <div className="App">
      <h1 className="App-bg-title">WTS</h1>
      <h2 className="App-title">Warehouse Tracking System</h2>

      <div className="App-container">
        <div className="App-station-header">
          <StationInput
            stations={ basics.stations }
            onStationChange={ (station) => {
              if (station !== '') {
                setShowOutput(true)
              }
            } }
          />

          <SessionExpirationLogger />
          <StationSettings />
        </div>
        <div className={ 'App-station-body App-station-body-' + basics.activity }>
          <div className="App-station-inner">
            { showOutput ? output : <>Select a Station</> }
          </div>
        </div>
      </div>
    </div>
  )
}
