import { useCallback, useContext, useEffect, useState } from 'react'

import { BasicsContext } from '../contexts/BasicsContext'
import { SessionsContext } from '../contexts/SessionsContext'
import { unixTime } from '../lib/time'

import Tabs, { Tab } from './Tabs'
import InactiveTab from './InactiveTab'
import SignInForm from './SignInForm'
import UserTabTitle from './UserTabTitle'
import UserTab from './UserTab'

export default function SessionTabHandler() {
  const { basics, basicsDispatch } = useContext(BasicsContext)
  const { sessions, sessionsDispatch } = useContext(SessionsContext)

  const [ tabsEnabled, setTabsEnabled ] = useState(true)
  const [ tabs, setTabs ] = useState<Tab[]>([])

  const onUserTabInactive = useCallback((session_id: string) => {
    if (basics.activeTab === session_id) {
      basicsDispatch({
        activeTab: '',
      })
    }
  }, [ basics.activeTab, basicsDispatch ])

  useEffect(() => {
    const tabs: Tab[] = sessions.current.map((session): Tab => {
      return {
        id: session.session_id,
        render: () => {
          return (
            <UserTab
              session={ session }
              onSubmitStart={ () => { setTabsEnabled(false) } }
              onSubmitEnd={ () => { setTabsEnabled(true) } }
            />
          )
        },
        title: <UserTabTitle session={ session } onInactive={ onUserTabInactive }  />,
        type: 'session',
      }
    })

    tabs.push( {
      btnClassName: 'Tab-sign-in-btn',
      id: 'sign-in-tab',
      title: 'Sign In',
      render: (
        <SignInForm onSignIn={ (session) => {
          console.log('onSignIn', session)
          basicsDispatch({
            activeTab: session.session_id,
          })
        } } />
      ),
      type: 'sign-in'
    } )

    setTabs(tabs)
  }, [ onUserTabInactive, basicsDispatch, sessions ])

  return (
    <Tabs
      tabs={ tabs }
      disabled={ ! tabsEnabled }
      inactiveTab={ <InactiveTab /> }
      onSelect={ (tab) => {
        basicsDispatch({
          activeTab: tab.id,
        })

        if (tab.type === 'session') {
          sessionsDispatch({
            active: sessions.current.find(s => s.session_id === tab.id),
            current: sessions.current.map(s => {
              if (s.session_id === tab.id) {
                s.last_usage = unixTime()
              }

              return s
            }),
          })
        }
      } }
      selected={ basics.activeTab }
    />
  )
}
