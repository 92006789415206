import React, { useMemo, useReducer } from 'react'

import { BasicInfoResponse } from '../lib/wts/basic'
import { StorageSet } from '../lib/wts/browser_storage'

export enum Activity {
  inactive = 'inactive',
  active = 'active',
}


export type BasicsData = BasicInfoResponse & {
  activeStation: string;
  activity: Activity;
  activeTab: string;
}

export type BasicsDispatch = React.Dispatch<Partial<BasicsData>>;

export interface IBasicsContext {
  basics: BasicsData;
  basicsDispatch: BasicsDispatch;
}

function defaultBasics(): BasicsData {
  return {
    employees: [],
    stations: [],
    activeStation: '',
    activity: Activity.inactive,
    activeTab: '',
  }
}

export const BasicsContext = React.createContext<IBasicsContext>({
  basics: defaultBasics(),
  basicsDispatch: () => {},
})

function reducer (state: BasicsData, change: Partial<BasicsData>): BasicsData {
  const result: BasicsData = {
    ...state,
    ...change,
  }

  StorageSet<string>('station', result.activeStation || '')
  document.title = 'WTS Scans - ' + result.activeStation

  StorageSet<Activity>('activity', result.activity)
  StorageSet<string>('active_tab', result.activeTab || '')

  return result
}

export function BasicsContextProvider(props: { children: any }) {
  const initial: BasicsData = defaultBasics()

  const [ basics, basicsDispatch ] = useReducer(reducer, initial)

  const memoizedContext = useMemo(() => {
    return { basics, basicsDispatch }
  }, [ basics, basicsDispatch ])

  return (
    <BasicsContext.Provider value={ memoizedContext }>
      { props.children }
    </BasicsContext.Provider>
  )
}
