import { PostSessionEnd } from '../lib/wts/session'
import { useContext, useEffect, useState } from 'react'
import { NetworkState } from '../lib/network'
import { GetLocalStation } from '../lib/wts/stations'
import { SessionsContext } from '../contexts/SessionsContext'

export default function SessionExpirationLogger() {
  const { sessions, sessionsDispatch } = useContext(SessionsContext)

  const [ networkState, setNetworkState ] = useState<NetworkState>(NetworkState.idle)

  useEffect(() => {
    const doNetwork = async () => {
      setNetworkState(NetworkState.pending)

      const station = GetLocalStation()

      if ( ! station) {
        setNetworkState(NetworkState.rejected)
        const timer = window.setTimeout(() => setNetworkState(NetworkState.idle), 5_000)
        return () => window.clearTimeout(timer)
      }

      const promises = sessions.expired.map(session => PostSessionEnd(station, session.employee))

      try {
        await Promise.all(promises)
      } catch (err) {
        setNetworkState(NetworkState.rejected)
        const timer = window.setTimeout(() => setNetworkState(NetworkState.idle), 5_000)
        return () => window.clearTimeout(timer)
      }

      sessionsDispatch({
        expired: [],
      })

      setNetworkState(NetworkState.resolved)
      const timer = window.setTimeout(() => setNetworkState(NetworkState.idle), 5_000)
      return () => window.clearTimeout(timer)
    }

    if (networkState === NetworkState.idle && sessions.expired.length > 0) {
      doNetwork()
    }
  }, [ sessions.expired, sessionsDispatch, networkState ])

  let msg: JSX.Element | null = null

  switch (networkState) {
    case NetworkState.idle:
      break
    case NetworkState.pending:
      console.log('SESSIONS TO EXPIRE', sessions.expired)
      msg = <>Expiring { sessions.expired.length } { 'Session' + (sessions.expired.length !== 1 ? 's' : '') }</>
      break
    case NetworkState.slow:
      break
    case NetworkState.resolved:
      msg = <>Successfully Expired Sessions</>
      break
    case NetworkState.rejected:
      msg = <>Failed to expire sessions!</>
      break
  }

  return (
    <div className="SEL-wrapper">{ msg }</div>
  )
}
