
export const StorageKey: string = 'WTS-SC-'

export function StorageSet<T>(key: string, value: T): void {
  console.log('StorageSet', key, value === '' ? '[EMPTY STRING]' : value)
  window.sessionStorage.setItem(StorageKey + key, JSON.stringify(value))
}

export function StorageGet<T>(key: string): T | null;
export function StorageGet<T>(key: string, defaultValue: T): T;
export function StorageGet<T>(key: string, defaultValue: T | null = null): T | null {
  console.log('StorageGet', key)

  const strValue = window.sessionStorage.getItem(StorageKey + key)

  if (strValue === null) {
    return defaultValue
  }

  return JSON.parse(strValue) as T
}
