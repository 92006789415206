import { BuildRequest, DoRequest, GetApiUrl } from '../network'
import { DoMockRequest } from '../mock-network'
import { FindOrderResult } from './order'

export interface ScanOrderResponse {
  success: boolean;
  message?: string;
}

export interface ScanOrderRequest {
  station: string;
  employee: string;
  order_number: string;
}

export function SendScanOrder(station: string, employee: string, orderNumber: number): Promise<ScanOrderResponse | Error> {
  const req = BuildRequest<ScanOrderRequest>('POST', GetApiUrl() + '/v1/wts/scan.json', {
    station,
    employee,
    order_number: orderNumber.toString(),
  })

  // return DoMockRequest(req, 0.25, { success: true })
  return DoRequest<ScanOrderResponse>(req)
}

export function FindOrders(orderNumber: number): Promise<FindOrderResult[] | Error> {
  const req = BuildRequest<FindOrderResult[]>('GET', GetApiUrl() + '/v1/wts/orders/' + orderNumber + '.json')

  return DoRequest<FindOrderResult[]>(req)
}
