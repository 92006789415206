import './Tabs.css'

export type Tab = {
  id: string;
  title: string | JSX.Element;
  render: JSX.Element | (() => JSX.Element);
  type: string;
  btnClassName?: string;
}

export interface TabsProps {
  tabs: Tab[];
  inactiveTab: any;
  selected?: string;
  disabled?: boolean;
  onSelect?: (tab: Tab, idx: number) => void;
}

function clean(v: string): string {
  v = v.toLowerCase()
  v = v.trim()
  v = v.replace(/[^a-zA-Z0-9]+/g, '-')
  v = v.replace(/--+/g, '-')

  return v
}

export default function Tabs(props: TabsProps) {
  const { tabs, disabled, inactiveTab, selected, onSelect } = props

  // console.log('!! TABS !!', tabs, selected)
  // console.log('selectedTab', selected)
  const renderTab = (tab: Tab) => {
    if (typeof tab.render === 'function') {
      return tab.render()
    }

    return tab.render
  }

  return (
    <div className="Tabs">
      <div className="TabList" role="tablist" aria-label="Tabs">
        {
          Object.values(tabs).map((tab, idx) => {
            const isActive = selected === tab.id
            const cleanID = clean(tab.id)

            // console.log('TAB TITLE?', tab.title)

            return (
              <button
                type="button"
                role="tab"
                className={ 'Tab-btn Tab-btn-select' + (tab.btnClassName ? ' ' + tab.btnClassName : '') + (isActive ? ' Tab-btn-active' : '') }
                aria-selected={ isActive }
                aria-controls={ `tab-${ cleanID }-panel` }
                id={ `tab-${ cleanID }-btn` }
                tabIndex={ idx }
                key={ tab.id }
                disabled={ disabled }
                onClick={ () => {
                  console.log('onSelect', tab, idx)
                  if (onSelect) {
                    onSelect(tab, idx)
                  }
                } }
              >{ tab.title }</button>
            )
          })
        }
      </div>
      <div className="TabPanels">
        { typeof selected === 'undefined' || selected === null || selected === ''
        ? <div role="tabpanel" className="TabPanel TabPanel-active" tabIndex={ 0 }>{ inactiveTab }</div>
        : null
        }

        {
          Object.values(tabs).map((tab, idx) => {
            const cleanID = clean(tab.id)
            const isActive = selected === tab.id

            return (
              <div
                id={ `tab-${ cleanID }-panel` }
                role="tabpanel"
                aria-labelledby={ `tab-${ cleanID }-btn` }
                className={ 'TabPanel' + (isActive ? ' TabPanel-active' : '') }
                key={ tab.id }
                tabIndex={ 0 }
                hidden={ ! isActive }
              >
                { isActive ? renderTab(tab) : null }
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
