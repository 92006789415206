import { useEffect, useState } from 'react'
import './StationSettingsForm.css'

interface StationSettingsFormProps {
  id: string;
  label: string;
  value: number;
  defaultValue: number;
  maxValue: number;
  note?: string;
  onSubmit: (num: number) => void;
}

export default function StationSettingsForm(props: StationSettingsFormProps) {
  const { id, label, value, defaultValue, maxValue, note, onSubmit } = props

  const [ strValue, setStrValue ] = useState(value.toString())

  useEffect(() => {
    setStrValue(value.toString())
  }, [ value ])

  const onBlurOrFocus = () => {
    const val = parseInt(strValue)

    if (isNaN(val) || val <= 0) {
      setStrValue(value.toString())
    }
  }

  return (
    <form
      className="SSM-form"
      onSubmit={ (ev) => {
        ev.preventDefault()

        onSubmit(parseInt(strValue))

        return false
      } }
    >
      <label htmlFor={ id } className="SSM-label">{ label }:</label>

      <button
        type="button"
        className="SSM-btn SSM-btn-icon SSM-btn-icon-minus"
        onClick={ () => {
          const val = parseInt(strValue)

          if (val > 0) {
            setStrValue((val - 1).toString())
          }
        } }
      >&minus;</button>

      <input
        id={ id }
        className="SSM-input"
        type="number"
        value={ strValue }
        max={ maxValue }
        onChange={ (ev) => {
          let val = parseInt(ev.target.value)

          if (! isNaN(val) && val > 0) {
            setStrValue(ev.target.value)
          } else if (val > maxValue) {
            val = maxValue
            setStrValue(val.toString())
          } else {
            setStrValue('')
          }
        } }
        onFocus={ onBlurOrFocus }
        onBlur={ onBlurOrFocus }
      />

      <button
        type="button"
        className="SSM-btn SSM-btn-icon SSM-btn-icon-plus"
        onClick={ () => {
          let val = parseInt(strValue) + 1

          if (val > maxValue) {
            val = maxValue
          }

          setStrValue(val.toString())
        } }
      >+</button>

      { note
        ? <span className="SSM-input-note">({ note })</span>
        : null
      }

      <button
        type="submit"
        className="SSM-btn SSM-btn-submit"
        disabled={ parseInt(strValue) === value || isNaN(parseInt(strValue)) || parseInt(strValue) <= 0 }
      >Save</button>
      <button
        type="button"
        className="SSM-btn SSM-btn-reset"
        onClick={ () => setStrValue(defaultValue.toString()) }
        disabled={ parseInt(strValue) === defaultValue }
      >Reset</button>
    </form>
  )
}
