import React, { useMemo, useReducer } from 'react'
import { StorageSet } from '../lib/wts/browser_storage'


export type TimeoutsDispatch = React.Dispatch<Partial<Timeouts>>;

export interface Timeouts {
  // userTimeout -- the amount of time elapsed (in seconds) before a user is
  // logged out, and we send a session END
  userTimeout: number;
  // submitTimeout -- the amount of time elapsed (in seconds) before we remove messaging
  // after a successful submission
  submitTimeout: number;
  // inactivityTimeout -- the amount of time elapsed (in seconds) before we de-select
  // a users tab. This isn't a log-out event
  inactivityTimeout: number;
}

export interface ITimeoutsContext {
  timeouts: Timeouts;
  timeoutsDispatch: TimeoutsDispatch;
}

export const DEFAULT_USER_TIMEOUT = 15 * 60
export const DEFAULT_SUBMIT_TIMEOUT = 30
export const DEFAULT_INACTIVITY_TIMEOUT = 60

export const TimeoutsContext = React.createContext<ITimeoutsContext>({
  timeouts: {
    userTimeout: DEFAULT_USER_TIMEOUT,
    submitTimeout: DEFAULT_SUBMIT_TIMEOUT,
    inactivityTimeout: DEFAULT_INACTIVITY_TIMEOUT,
  },
  timeoutsDispatch: () => {},
})

function reducer (state: Timeouts, change: Partial<Timeouts>): Timeouts {
  const result: Timeouts = {
    ...state,
    ...change,
  }

  console.log('Timeouts Reducer', result)
  StorageSet('station_timeouts', result)

  return result
}

export function TimeoutsContextProvider(props: { children: any }) {
  const initial: Timeouts = {
    userTimeout: 15 * 60,
    submitTimeout: 30,
    inactivityTimeout: 60,
  }

  const [ timeouts, timeoutsDispatch ] = useReducer(reducer, initial)

  const memoizedContext = useMemo(() => {

    return { timeouts, timeoutsDispatch }
  }, [ timeouts, timeoutsDispatch ])

  return (
    <TimeoutsContext.Provider value={ memoizedContext }>
      { props.children }
    </TimeoutsContext.Provider>
  )
}
