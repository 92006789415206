import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import './UserTab.css'

import { Session } from '../lib/wts/session'
import { unixTime } from '../lib/time'
import { NetworkState } from '../lib/network'
import { FindOrders, ScanOrderResponse, SendScanOrder } from '../lib/wts/scan'
import { TimeoutsContext } from '../contexts/TimeoutsContext'
import { SessionsContext } from '../contexts/SessionsContext'
import { GetLocalStation } from '../lib/wts/stations'
import { getErrorString } from '../lib/error'
import { FindOrderResult } from '../lib/wts/order'
import CancelledOrdersModal from './CancelledOrdersModal'

interface UserTabProps {
  session: Session;

  onSubmitStart: () => void;
  onSubmitEnd: () => void;
}

export default function UserTab(props: UserTabProps) {
  const { session, onSubmitStart, onSubmitEnd } = props

  const { timeouts } = useContext(TimeoutsContext)
  const { sessions, sessionsDispatch } = useContext(SessionsContext)

  const [ orderNumber, setOrderNumber ] = useState<string>('')
  const [ networkState, setNetworkState ] = useState<NetworkState>(NetworkState.idle)
  const [ doSubmit, setDoSubmit ] = useState(false)
  const [ error, setError ] = useState('')
  const [ completed, setCompleted ] = useState(false)
  const [ sentOrderNumber, setSentOrderNumber ] = useState('')
  const [ foundOrders, setFoundOrders ] = useState<FindOrderResult[]>([])

  const inputRef = useRef<any>()

  console.log('UserTab render')

  const onInteraction = useCallback((shouldFocus: boolean) => {
    console.log('onInteraction -- shouldFocus ->', shouldFocus)

    if (shouldFocus && inputRef.current) {
      const ele = inputRef.current as HTMLInputElement
      ele.focus()
      // console.log('tried to focus')
    }

    let shouldUpdate = false

    const now = unixTime()

    const updated = sessions.current.map(s => {
      if (s.session_id === session.session_id) {
        s.last_usage = now

        if (now - s.last_usage > timeouts.inactivityTimeout / 2) {
          shouldUpdate = true
        }
      }

      return s
    })

    if (shouldUpdate) {
      sessionsDispatch({
        current: updated,
      })
    }

  }, [ timeouts.inactivityTimeout, session, sessions, sessionsDispatch ])

  const onSignOutSession = useCallback(() => {
    const updated = sessions.current.filter(s => s.session_id !== session.session_id)
    const expired = [ ...sessions.expired, session ]

    sessionsDispatch({
      active: sessions.active?.session_id === session.session_id ? undefined : sessions.active,
      current: [ ...updated ],
      expired: expired,
    })
  }, [ session, sessions, sessionsDispatch ])

  useEffect(() => {
    if (sessions.active &&
        inputRef.current &&
        sessions.active.session_id === session.session_id) {
      console.log('focus attempt number bajillion')
      inputRef.current.focus()
    }
  }, [ sessions, session.session_id ])

  useEffect(() => {
    console.log('useEffect for inputRef', inputRef.current)

    if (inputRef.current) {
      const ele = inputRef.current as HTMLInputElement

      ele.focus()
      console.log('attempting to focus ele')
    }
  }, [ inputRef ])

  useEffect(() => {
    if (completed) {
      const timer = window.setTimeout(() => {
        setNetworkState(NetworkState.idle)
        setCompleted(false)
      }, timeouts.submitTimeout * 1_000)

      return () => window.clearTimeout(timer)
    }
  }, [ timeouts.submitTimeout, completed ])

  useEffect(() => {
    const doNetwork = async () => {
      onSubmitStart()

      // Reset found orders when doing network stuff
      setFoundOrders([])

      onInteraction(false)
      setNetworkState(NetworkState.pending)
      setDoSubmit(false)

      const station = GetLocalStation()

      if ( ! station) {
        setError('No station selected!')
        setNetworkState(NetworkState.rejected)
        onSubmitEnd()
        return
      }

      const { employee } = session

      let scanOrderRsp: ScanOrderResponse | Error
      let findOrderRsp: FindOrderResult[] | Error
      let timer: number = -1

      try {
        timer = window.setTimeout(() => setNetworkState(NetworkState.slow), 5_000)
        scanOrderRsp = await SendScanOrder(station, employee, parseInt(orderNumber))
        findOrderRsp = await FindOrders(parseInt(orderNumber))
        window.clearTimeout(timer)

        console.log('orders', findOrderRsp)
      } catch (err) {
        setError(getErrorString(err))
        window.clearTimeout(timer)
        setNetworkState(NetworkState.rejected)
        onInteraction(true)
        onSubmitEnd()
        return
      }

      if (scanOrderRsp instanceof Error) {
        setError(`${ scanOrderRsp.name } - ${ scanOrderRsp.message }`)
        setNetworkState(NetworkState.rejected)
        onInteraction(true)
      }

      // We're not going to call this a failure if this failed as long as the previous succeeded
      if (findOrderRsp instanceof Error) {
        setError(`${ findOrderRsp.name } - ${ findOrderRsp.message }`)
        onInteraction(true)
      } else {
        console.log('SET FOUND ORDERS', findOrderRsp.length, findOrderRsp)
        setFoundOrders(findOrderRsp)
      }

      setSentOrderNumber(orderNumber)
      setOrderNumber('')
      setNetworkState(NetworkState.resolved)
      setCompleted(true)
      onInteraction(true)
      onSubmitEnd()
    }

    if (networkState === NetworkState.idle && doSubmit) {
      doNetwork()
    }

  }, [ doSubmit, session, sessions, onInteraction, orderNumber, networkState ])

  const shouldDisable = networkState === NetworkState.pending || networkState === NetworkState.slow
  let msg: React.JSX.Element | null = null
  let ordersEle: React.JSX.Element | null = null

  switch (networkState) {
    case NetworkState.idle:
      ordersEle = (
        <CancelledOrdersModal
          orders={ foundOrders }
          onClose={ () => setFoundOrders([]) }
        />
      )

      break
    case NetworkState.pending:
      msg = <div className="UserTab-response UserTab-rsponse-pending">Submitting! Please
        Wait...</div>
      break
    case NetworkState.slow:
      msg = <div className="UserTab-response UserTab-response-pending">Still Submitting...
        Please Wait</div>
      break
    case NetworkState.resolved:
      msg = <div className="UserTab-response UserTab-response-success">Completed!
        Sent <strong>{ sentOrderNumber }</strong></div>

      ordersEle = (
        <CancelledOrdersModal
          orders={ foundOrders }
          onClose={ () => setFoundOrders([]) }
        />
      )
      break
    case NetworkState.rejected:
      msg = (
        <div className="UserTab-response UserTab-response-failure">
          <div>Failure! { error }</div>
          <button
            type="button"
            onClick={ () => {
              setNetworkState(NetworkState.idle)
              setDoSubmit(true)
            } }
          >Retry</button>
        </div>
      )
      break

  }

  return (
    <form
      className="UserTab"
      onSubmit={ (ev) => {
        ev.preventDefault()

        if (shouldDisable) {
          return false
        }

        if (orderNumber.length === 0) {
          return false
        }

        setNetworkState(NetworkState.idle)
        setDoSubmit(true)
        return false
      } }
    >
      <div className="UserTab-inner">
        <h3 className="UserTab-title">Scan an Order</h3>
        <div className="UserTab-key-val">
          <span className="UserTab-key">Station:</span>
          <span className="UserTab-val">{ GetLocalStation() }</span>
        </div>
        <div className="UserTab-key-val">
          <span className="UserTab-key">User:</span>
          <span className="UserTab-val">{ session.employee }</span>
        </div>

        <input
          ref={ inputRef }
          type="text"
          id="order_num"
          name="order_num"
          className="UserTab-input-order"
          maxLength={ 20 }
          value={ orderNumber }
          onFocus={ () => onInteraction(false) }
          onClick={ () => onInteraction(false) }
          onBlur={ () => onInteraction(false) }
          onChange={ (ev) => {
            setOrderNumber(ev.target.value)
          } }
          autoComplete="user-input-barcode-dont-autocomplete"
          disabled={ shouldDisable }
          required
        />

        { msg }

        { ordersEle }

        <div className="UserTab-btns">
          <button
            type="submit"
            className="UserTab-btn UserTab-btn-submit"
            disabled={ shouldDisable }
          >Submit &raquo;</button>

          <button
            type="button"
            className="UserTab-btn UserTab-btn-signout"
            onClick={ () => onSignOutSession() }
            disabled={ shouldDisable }
          >&laquo; Sign Out
          </button>
        </div>
      </div>
    </form>
  )
}
