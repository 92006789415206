
export function onlyUniqueElements<T>(inp: T[]): T[] {
  const out: T[] = []

  inp.forEach((val, idx) => {
    if ( ! out.includes(val)) {
      out.push(val)
    }
  })

  return out
}
