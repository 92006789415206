import './InactiveTab.css'

export default function InactiveTab() {
  return (
    <div className="InactiveTab">
      <p>
        Select a user above to scan an order<br />
        <em>or sign in as a new user.</em>
      </p>
    </div>
  )
}
