import { BuildRequest, DoRequest, GetApiUrl } from '../network'
import { DoMockRequest } from '../mock-network'

export interface BasicInfoResponse {
  employees: string[];
  stations: string[];
}

export async function GetBasicInfo(): Promise<BasicInfoResponse | Error> {
  const req = BuildRequest('GET', GetApiUrl() + '/v1/basics.json')

  // return DoMockRequest<BasicInfoResponse>(req, 1, {
  //   employees: [
  //     'Stewart, Jon',
  //     'Colbert, Stephen',
  //     'Oliver, John',
  //   ],
  //   stations: [
  //     'Birthstone',
  //     'Boxing',
  //     'Embroidery',
  //     'End of day Cleaning / Restocking',
  //     'Flats',
  //     'Gift Basket In',
  //     'Gift Kits',
  //     'Gift Basket Out',
  //     'Giftwrap',
  //     'Handstamp',
  //     'Impact',
  //     'Inspection Fail',
  //     'Inspection',
  //     'Inventory Receiving',
  //     'Inventory',
  //     'Laser',
  //     'Machine Maintenance',
  //     'Mail Run / Handling',
  //     'Packing',
  //     'Photo Proofing',
  //     'Picking',
  //     'Pre-Made Madeline\'s',
  //     'Pre-Made Gift Boxes',
  //     'Pre-Made Foil Cards',
  //     'Press',
  //     'Quality Fail',
  //     'Quality Pass',
  //     'Quality Checks',
  //     'Rings',
  //     'Shipping',
  //     'Tag Removal from Pouches',
  //     'Training',
  //     'Wholesale Orders',
  //   ],
  // })

  return DoRequest(req)
}
