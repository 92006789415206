import { BuildRequest, DoRequest, GetApiUrl } from '../network'
import { DoMockRequest } from '../mock-network'
import { unixTime } from '../time'
import { getRandomInt } from '../math'

export interface Session {
  session_id: string;
  employee: string;
  start_on: number;
  last_usage: number;
}

const SESSION_CHARS: string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
const CHARS_LENGTH: number = SESSION_CHARS.length

export function MakeSessionID(): string {
  let session = ''

  for (let i = 0; i < 15; i++) {
    session += SESSION_CHARS[ getRandomInt(0, CHARS_LENGTH - 1) ]
  }

  console.log('MakeSessionID', session)
  return session
}

export function CreateSession(employee: string): Session {
  const now = unixTime()

  return {
    session_id: MakeSessionID(),
    employee,
    start_on: now,
    last_usage: now,
  }
}

export interface SessionChangeRequest {
  station: string;
  employee: string;
}

export interface SessionChangeResponse {
  success: boolean;
  message?: string;
}

export async function PostSessionStart(station: string, employee: string): Promise<SessionChangeResponse | Error > {
  const req = BuildRequest<SessionChangeRequest>('POST', GetApiUrl() + '/v1/wts/session/start.json', {
    station,
    employee,
  })

  // return DoMockRequest(req, 5, { success: true })
  return DoRequest(req)
}

export async function PostSessionEnd(station: string, employee: string): Promise<SessionChangeResponse | Error > {
  const req = BuildRequest<SessionChangeRequest>('POST', GetApiUrl() + '/v1/wts/session/end.json', {
    station,
    employee,
  })

  // return DoMockRequest(req, 5, { success: true })
  return DoRequest(req)
}
