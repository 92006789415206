import { BasicInfoResponse } from './wts/basic'

export const DEFAULT_TIMEOUT = 29_900 // 29.9s

export enum NetworkState {
  idle     = 'idle',
  pending  = 'pending',
  slow     = 'slow',
  resolved = 'resolved',
  rejected = 'rejected',
}

export function GetApiUrl(): string {
  return process.env.REACT_APP_API_URL || ''
}

export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

export type BuiltRequest = {
  url: string;
  init: RequestInit;
  aborter: AbortController;
  timeout: number;
}

const ResponseCache: Record<string, any> = {}

const CacheKey = 'WTS-SC-'

export function StoreCache<T>(id: number, item: T) {
  ResponseCache[CacheKey + id] = item
}

export function RetrieveCache<T>(id: number): T|null {
  if (typeof ResponseCache[CacheKey + id] === 'undefined') {
    return null
  }

  return ResponseCache[CacheKey + id] as T
}

export function BuildRequest<T>(method: RequestMethod, url: string, body?: T, timeout?: number): BuiltRequest {
  const aborter = new AbortController()

  const init: RequestInit = {
    method: method,
    mode: 'cors',
    cache: 'no-cache',
    signal: aborter.signal,
    headers: {'Content-Type': 'application/json'},
  }

  if (method !== 'GET') {
    init.body = JSON.stringify(body)
  }

  return {
    url: url,
    init: init,
    aborter: aborter,
    timeout: typeof timeout === 'number' ? timeout : DEFAULT_TIMEOUT,
  }
}

export async function DoRequest<T>(req: BuiltRequest): Promise<T|Error> {
  const abortTimer = setTimeout(() => { req.aborter.abort() }, req.timeout)

  const response = await fetch(req.url, req.init)
  clearTimeout(abortTimer)

  if (!response.ok) {
    console.error('Response to request was not-OK:', response.status)
    console.info('Request State:', req)
    return new Error('Response was not-OK: ' + response.status.toString());
  }

  return await response.json()
}
