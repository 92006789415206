import { useContext, useEffect, useState } from 'react'
import './SignInForm.css'

import { CreateSession, PostSessionStart, Session } from '../lib/wts/session'
import { BasicsContext } from '../contexts/BasicsContext'
import { NetworkState } from '../lib/network'
import { SessionsContext } from '../contexts/SessionsContext'

interface SignInFormProps {
  onSignIn: (session: Session) => void;
}

export default function SignInForm(props: SignInFormProps) {
  const { basics, basicsDispatch } = useContext(BasicsContext)
  const { sessions, sessionsDispatch } = useContext(SessionsContext)
  const { onSignIn } = props

  const [ employee, setEmployee ] = useState<string>('')
  const [ isInvalid, setIsInvalid ] = useState(false)
  const [ networkState, setNetworkState ] = useState(NetworkState.idle)
  const [ doSubmit, setDoSubmit ] = useState(false)
  const [ error, setError ] = useState('')

  const sessionEmployees = sessions.current.map(session => session.employee)

  useEffect(() => {
    const doNetwork = async () => {
      setNetworkState(NetworkState.pending)
      setDoSubmit(false)

      try {
        const rsp = await PostSessionStart(basics.activeStation, employee)

        if (! rsp || rsp instanceof Error) {
          setError(`Request failed: ${ JSON.stringify(rsp) }`)
          setNetworkState(NetworkState.rejected)
          return
        }

        if ( ! rsp.success) {
          setError(`Request failed: ${ rsp.message || 'Unknown Reason' }`)
          setNetworkState(NetworkState.rejected)
          return
        }

      } catch (err) {
        setError(`Request failed: ${ JSON.stringify(err) }`)
        setNetworkState(NetworkState.rejected)
        return
      }

      const active = CreateSession(employee)
      const current = [...sessions.current, active]

      setNetworkState(NetworkState.resolved)

      sessionsDispatch({
        active,
        current,
      })

      basicsDispatch({
        activeTab: active.session_id,
      })

      onSignIn(active)
    }

    if (basics.activeStation !== null && doSubmit && networkState === NetworkState.idle) {
      doNetwork()
    }

  }, [ sessions, sessionsDispatch, doSubmit, onSignIn, basics.activeStation, employee, networkState ])

  if (basics.activeStation === null || basics.activeStation === '') {
    return <>Must have station selected!</>
  }

  let msg: JSX.Element | null = null

  switch (networkState) {
    case NetworkState.idle:
      break
    case NetworkState.pending:
      msg = <>Signing In... Please Wait</>
      break
    case NetworkState.slow:
      msg = <>Taking a little bit of time, please wait!</>
      break
    case NetworkState.resolved:
      msg = <>Signed In!</>
      break
    case NetworkState.rejected:
      msg = <>Something went wrong: { error }</>
      break

  }

  return (
    <div className="SignInForm-wrapper">
      <form
        className="SignInForm-form"
        onSubmit={ (ev) => {
          ev.preventDefault()

          console.group('SignInForm submit')
          console.log('employee', employee)
          console.log('employees', basics.employees)
          console.log('sessionEmployees', sessionEmployees)
          console.log('sessionEmployees includes employee', sessionEmployees.includes(employee))
          console.groupEnd()

          if (basics.employees.includes(employee) && ! sessionEmployees.includes(employee)) {
            setIsInvalid(false)
            // should trigger network event ~
            setNetworkState(NetworkState.idle)
            setDoSubmit(true)
          } else {
            setIsInvalid(true)
          }

          return false
        } }
      >
        <label htmlFor="SignInForm-input" className="SignInForm-label">Sign In As:</label>
        <input
          id="SignInForm-input"
          list="SignInForm-Users"
          className={ 'SignInForm-input' + (isInvalid ? ' SignInForm-input-invalid' : '') }
          placeholder="Begin Typing User"
          value={ employee }
          disabled={ networkState !== NetworkState.idle || doSubmit }
          onChange={ (ev) => {
            setIsInvalid( ! basics.employees.includes(ev.target.value))
            setEmployee(ev.target.value)
          } }
          required
        />
        <datalist id="SignInForm-Users">
          { basics.employees
              .filter(employee => !sessionEmployees.includes(employee))
              .map(employee => <option key={ employee } value={ employee } />) }
        </datalist>

        <div className="SignInForm-msg">
          { msg }
        </div>

        <div className="SignInForm-btn-wrapper">
          <button
            type="submit"
            className="SignInForm-btn"
            disabled={ networkState !== NetworkState.idle || doSubmit }
          >Sign In &raquo;</button>
        </div>
      </form>

    </div>
  )
}
