
export function getErrorString(err: unknown): string {
  const res = resolveCatchErr(err)

  if (typeof res === 'string') {
    return res
  }

  return `${ res.name } - ${ res.message }`
}

export function resolveCatchErr(err: unknown): Error | string {
  if (typeof err === 'string') {
    return err
  }

  if (err instanceof Error) {
    return err
  }

  return JSON.stringify(err)
}
