import './CancelledOrdersModal.css'
import { FindOrderResult } from '../lib/wts/order'

interface Props {
  orders: FindOrderResult[];
  onClose: () => void;
}

export default function CancelledOrdersModal(props: Props) {
  const { orders, onClose } = props

  if (orders.length === 0) {
    return null
  }

  const anyCancelledOrders = orders.findIndex(order => order.order.cancelled_at !== "")

  if (anyCancelledOrders < 0) {
    return null
  }

  const order = orders[anyCancelledOrders]

  return (
    <div className="CancelledOrdersModal">
      <div className="COM-Title">ORDER <b>{ order.order.order_number }</b> WAS CANCELLED</div>
      <div className="COM-Body">
        Order <b>{ order.order.order_number }</b> on store <b>{ order.store_name }</b> was cancelled at { order.order.cancelled_at }</div>
      <button type="button" className="COM-Btn" onClick={ onClose }>Okay</button>
    </div>
  )
}
