import { useState } from 'react'
import './StationSettings.css'

import StationSettingsModal from './StationSettingsModal'

interface StationSettingsProps {

}

export default function StationSettings(props: StationSettingsProps) {

  const [ showSettings, setShowSettings ] = useState(false)

  return (
    <div className="StationSettings-wrapper">
      <button
        type="button"
        className="StationSettings-btn StationSettings-btn-open"
        onClick={ () => {
          setShowSettings( ! showSettings)
        } }
      >Station Settings</button>
      { showSettings ? <StationSettingsModal /> : null }
    </div>
  )
}
