
const elapsedTimeTable: Record<string, (v: number) => number> = {
  'Y': v => v / (12 * 30 *  7 * 24 * 60 * 60),
  'M': v => v / (30 *  7 * 24 * 60 * 60),
  'w': v => v / (7 * 24 * 60 * 60),
  'd': v => v / (24 * 60 * 60),
  'h': v => v / (60 * 60),
  'm': v => v / 60,
  's': v => v % 60,
}

export function secondsToElapsedTime(seconds: number, onlyLargestUnit = true): string {

  const unitsOut: string[] = []

  Object.keys(elapsedTimeTable).forEach(unit => {
    const fn = elapsedTimeTable[unit]

    const value = Math.floor(fn(seconds))

    // console.log('seconds', seconds, unit, fn(seconds), value)

    if (value > 0) {
      unitsOut.push(`${ value }${ unit }`)
    }
  })

  if (onlyLargestUnit) {
    return unitsOut[0] || '0s'
  }

  return unitsOut.join('')
}

export function unixTime(): number {
  return Math.round(Date.now() / 1_000)
}
